import React from "react";
import "./Mid_Sec.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import travel from "../../Asset/Image/travel.png";
import architecture from "../../Asset/Image/architecture.png";
import interior from "../../Asset/Image/interior.png";
import game from "../../Asset/Image/game.png";
import food from "../../Asset/Image/food.png";
import Fashion from "../../Asset/Image/Fashion.png";
import travelBackvideo from "../../Asset/video/travelBackvideo.mp4";
import foodvideo from "../../Asset/video/food.mp4";
import fashion from "../../Asset/video/fashion.mp4";
import interiorvideo from "../../Asset/video/interior.mp4";
import architecturevideo from "../../Asset/video/Architecture.mp4";
import gaming from "../../Asset/video/gaming.mp4";
import HoverVideoPlayer from "react-hover-video-player";

const Category = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Change this value to adjust number of slides for larger screens
    slidesToScroll: 1,
    autoplay: true,
    lazyLoad: false,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for tablets
        settings: {
          slidesToShow: 2, // Change this value to adjust number of slides for tablets
        },
      },
      {
        breakpoint: 767, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1, // Change this value to adjust number of slides for mobile screens
        },
      },
    ],
  };

  return (
    <div className="mid_main">
      <div className="Animated_text">
        <Slider {...settings}>
          {data.map((caro_data) => (
            <HoverVideoPlayer
              key={caro_data.name}
              className="card"
              videoSrc={caro_data.backvid}
              pausedOverlay={
                <div className="overlay">
                  <img
                    className="img_mid"
                    src={caro_data.img}
                    alt={caro_data.name}
                  />
                  <div className="heading_text">{caro_data.name}</div>
                  <div className="text_content">{caro_data.tex}</div>
                </div>
              }
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Category;

const data = [
  {
    name: "Travel",
    text: "Transform your travel experience with immersive Extended Reality (XR) technology.",
    img: travel,
    backvid:
      " https://xeroverse-media.s3.ap-south-1.amazonaws.com/travelBackvideo.mp4",
  },
  {
    name: "Interior",
    text: "Virtual showrooms where users can browse through a curated collection of furniture and decor items.",
    img: interior,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/interior.mp4",
  },
  {
    name: "Architecture",
    text: "Facilitate collaboration among stakeholders, architects, and clients.",
    img: architecture,
    backvid:
      "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Architecture.mp4",
  },
  {
    name: "Gaming",
    text: "Allowing players to be active participants in the narrative through immersive environments and interactive elements.",
    img: game,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/gaming.mp4",
  },
  {
    name: "Fashion",
    text: "Implement 3D models and AR elements to showcase products in a more interactive and engaging way.",
    img: Fashion,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/fashion.mp4",
  },
  {
    name: "Food",
    text: "VR allows customers to see 3D models of products, examine packaging",
    img: food,
    backvid: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/food.mp4",
  },
];
