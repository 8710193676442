import React, { useState } from "react";
import "./Faq.css";

const FAQItem = ({ question, answer }) => {
  const [hovered, setHovered] = React.useState(false);

  return (
    <div
      className="faq-item"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="question">{question}</div>
      {hovered && <div className="answer">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    {
      question: "What sets Xeroverse apart in the VR industry?",
      answer:
        "Xeroverse stands out with its innovative approach, top-notch quality, and unmatched customer satisfaction rates.",
    },
    {
      question: "How can our Chennai businesses benefit from AR services?",
      answer:
        "By leveraging our AR services, businesses in Chennai can enhance customer engagement, streamline processes, and boost productivity.",
    },

    {
      question: "Why choose Xeroverse for Digital Twin solutions in Chennai?",
      answer:
        "Xeroverse offers state-of-the-art Digital Twin solutions, enabling Chennai enterprises to visualize, analyze, and optimize their operations like never before.",
    },
    {
      question:
        "Why Xeroverse is the Ultimate Destination for VR, AR, 3D Services, and Digital Twin in Chennai?",
      answer:
        "Unleash the power of immersive experiences, cutting-edge technology, and unparalleled expertise with Xeroverse.",
    },
    {
      question:
        "What makes Xeroverse the go-to choice for VR, AR, and 3D services in Chennai?",
      answer:
        "Xeroverse takes Chennai businesses beyond imagination with advanced VR, AR, and 3D services tailored for success in the digital realm.",
    },
    {
      question:
        " Why Xeroverse is Your Gateway to Stellar Enterprise Solutions in Chennai?",
      answer:
        " Elevate your business with Xeroverse's transformative VR, AR, 3D services, and Digital Twin solutions designed to drive growth and innovation like never before.",
    },
  ];

  const firstColumn = faqData.slice(0, 3);
  const secondColumn = faqData.slice(3);

  return (
    <div className="faq-cont">
      <div className="faq-container">
        <div className="faq-header">
          <h2 className="faq_question">
            Explore Our Cutting-Edge VR, AR, 3D Services, and Digital Twin
            Offerings in Chennai
          </h2>
          {/* <div className="search-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <circle
                cx="9.44005"
                cy="8.44073"
                r="6.66043"
                transform="rotate(-40 9.44005 8.44073)"
                stroke="white"
                stroke-width="1.5"
              />
              <path
                d="M14.2539 13.5371L18.7534 18.8994"
                stroke="white"
                stroke-width="1.5"
              />
            </svg>
          </div> */}
        </div>
        <div className="faq-columns">
          <div className="faq-column">
            {firstColumn.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
          <div className="faq-column">
            {secondColumn.map((item, index) => (
              <FAQItem
                key={index}
                question={item.question}
                answer={item.answer}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
