import React, { useState } from "react";
import "./logo_contact.css";
import xerologo from "../../Asset/Image/Xeroverse logo.png";
import ContactUs from "../ContactUs/ContactUs";
import { Link } from "react-router-dom";

const LogoContact = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleClick = () => {
    setShowContactForm(true);
  };

  const closeContactForm = () => {
    setShowContactForm(false);
  };

  return (
    <header className="logo-contact-header">
      <Link to="/" className="logo-link">
        <div className="logo-wrapper">
          <div className="logo-3d">
            <img src={xerologo} alt="Xeroverse Logo" className="xero_logo" />
          </div>
        </div>
      </Link>
      <button className="contact-button" onClick={handleClick}>
        Contact Us
      </button>
      {showContactForm && <ContactUs onClose={closeContactForm} />}
    </header>
  );
};

export default LogoContact;
