import React from "react";
import "./mid.css";
import digitaltwin from "../../Asset/Image/human_3061364.png";
import digitaltwi from "../../Asset/Image/virtual-reality.png";
import threedsolution from "../../Asset/Image/building_6017809.png";

const Mid_box = () => {
  return (
    <div className="extented_main">
      <div className="Extend_reality">
        <div className="promotion">
          <div className="front">
            <img className="md_img" src={digitaltwin} alt="Vision Icon" />
            <h1 className="Heading_two">Digital Twin</h1>
          </div>
          <div className="back">
            <p className="promotion_text">
              Digital twins in XR merge virtual replicas with real-time data,
              enabling immersive interaction for remote maintenance, training,
              and predictive analysis. This technology transforms complex system
              management by blending digital and physical realms.
            </p>
          </div>
        </div>
        <div className="promotion">
          <div className="front">
            <img className="md_img" src={digitaltwi} alt="Vision Icon" />
            <h1 className="Heading_one">Extended Reality Solution</h1>
          </div>
          <div className="back">
            <p className="Extended_text">
              XR solutions blend physical and virtual worlds using AR, VR, and
              MR, revolutionizing digital interaction across industries. These
              immersive tools enhance productivity, streamline processes, and
              create engaging experiences.
            </p>
          </div>
        </div>
        <div className="promotion">
          <div className="front">
            <img className="md_img" src={threedsolution} alt="Vision Icon" />
            <h1 className="Heading_two">3D Promotion</h1>
          </div>
          <div className="back">
            <p className="promotion_text">
              3D services combine digital modeling and printing, driving
              innovation across industries. From virtual tours in architecture
              to 3D-printed organs in medicine, these technologies enable rapid
              prototyping and customization, transforming fields like
              engineering and entertainment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mid_box;
