import React, { useState, useEffect } from "react";
import "./Nav.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <nav>
      <div className="menu-icon" onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          style={{ stroke: "#ff1493" }} // Inline style to set the color
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h16m-7 6h7"
          />
        </svg>
      </div>
      <ul className={isOpen ? "active" : ""}>
        <li>
          <Link to="/" onClick={closeMenu}>
            Home
          </Link>
        </li>
        <li>
          <Link to="/about-us" onClick={closeMenu}>
            About Us
          </Link>
        </li>
        <li>
          <Link to="/service" onClick={closeMenu}>
            Service
          </Link>
        </li>
        <li>
          <Link to="/Blog" onClick={closeMenu}>
            Blog
          </Link>
        </li>
        <li>
          <Link to="/Product" onClick={closeMenu}>
            Product
          </Link>
        </li>
        {/* <li>
          <svg
            className="Nav_search"
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
          >
            <circle
              cx="10.4401"
              cy="10.4407"
              r="6.66043"
              transform="rotate(-40 10.4401 10.4407)"
              stroke="#ffffff"
              strokeWidth="1.5"
            />
            <path
              d="M15.2539 15.5371L19.7534 20.8994"
              stroke="#ffffff"
              strokeWidth="1.5"
            />
          </svg>
        </li> */}
      </ul>
    </nav>
  );
};

export default Navbar;
