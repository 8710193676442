import React, { useState, useEffect } from "react";
import "./TestimonialSlider.css";

const TestimonialSlider = () => {
  const testimonials = [
    {
      text: "Xeroverse's digital twin technology gave us a competitive edge. Their professionalism and expertise made the implementation seamless. Impressed!",
      company: "Tech Solutions Ltd.",
      position: "Project Manager",
      rating: 5,
    },
    {
      text: "Xeroverse truly transformed our business with their cutting-edge VR, AR, and 3D services. Their digital twin solutions have revolutionized our operations. Highly recommended!",
      company: "ABC Corporation",
      position: "CEO",
      rating: 5,
    },
    {
      text: "We partnered with Xeroverse for our AR needs, and they exceeded our expectations. Their attention to detail and innovative approach set them apart.",
      company: "XYZ Enterprises",
      position: "CTO",
      rating: 4,
    },
    {
      text: "The team at Xeroverse is phenomenal! Their VR solutions boosted our sales and enhanced customer engagement. A game-changer in the industry.",
      company: "123 Innovation",
      position: "Marketing Manager",
      rating: 5,
    },
    {
      text: "We are thrilled with Xeroverse's 3D services. The quality and attention to detail in their work are unparalleled. Exceptional results every time!",
      company: "Global Innovate Corp.",
      position: "Lead Designer",
      rating: 5,
    },
    {
      text: "Xeroverse's VR solutions opened up new possibilities for our business. Their team's dedication and creativity delivered outstanding results. A trusted partner!",
      company: "Innovative Ventures Inc.",
      position: "COO",
      rating: 4,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationDirection, setAnimationDirection] = useState("slide-in");

  const goToSlide = (index) => {
    setAnimationDirection("slide-out");
    setTimeout(() => {
      setCurrentIndex(index);
      setAnimationDirection("slide-in");
    }, 500);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToSlide((currentIndex + 1) % testimonials.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [currentIndex, testimonials.length]);

  const StarRating = ({ rating }) => {
    return (
      <div className="star-rating">
        {[1, 2, 3, 4, 5].map((star) => (
          <i key={star} className={star <= rating ? "star filled" : "star"}>
            ★
          </i>
        ))}
      </div>
    );
  };

  return (
    <div className="testimonial-container">
      <div className="testimonial-title">Client Testimonials</div>
      <div className={`testimonial-content ${animationDirection}`}>
        <div className="testimonial-text">
          {testimonials[currentIndex].text}
        </div>
        <StarRating rating={testimonials[currentIndex].rating} />
        <div className="testimonial-company">
          {testimonials[currentIndex].company}
        </div>
        <div className="testimonial-position">
          {testimonials[currentIndex].position}
        </div>
      </div>
      <div className="dot-indicators">
        {testimonials.map((_, index) => (
          <div
            key={index}
            className={`dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => goToSlide(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default TestimonialSlider;
