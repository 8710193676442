import React from "react";
import { Link } from "react-router-dom";
import { FaLinkedin, FaEnvelope, FaWhatsapp } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="foot">
      <div className="footer">
        <div className="logo_sec">
          <p className="logo_s">XEROVERSE</p>
        </div>
        <div className="footer_content">
          <div className="pages">
            <p className="getintouch">Pages</p>
            <ul>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/service">Services</Link>
              </li>
              <li>
                <Link to="/blog">Blogs</Link>
              </li>
            </ul>
          </div>

          <div className="location">
            <p className="getintouch">Location</p>
            CNDE Lab, 2nd floor, <br />
            Indian Institute Of Technology, Chennai, <br />
            Tamil Nadu 600036
          </div>

          <div className="contact-details">
            <p className="getintouch">Contact Details</p>
            📧 info@xeroverse.in
            <br />
            📞 +91 7571006656
          </div>

          <div className="get-in-touch">
            <p className="getintouch">Get in touch</p>
            <div className="Social_media">
              <a href="https://www.linkedin.com/">
                <FaLinkedin />
              </a>
              <a href="mailto:info@xeroverse.in">
                <FaEnvelope />
              </a>
              <a href="https://wa.me/917571006656">
                <FaWhatsapp />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
