import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceItem = ({ name, icon, brands, brand }) => {
  return (
    <div className="project-item">
      <div className="service-icon">
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className="service-name">{name}</div>
      {brands && (
        <div className="brands">
          {brands.map((b, index) => (
            <span key={index} className="brand">
              {b}
            </span>
          ))}
        </div>
      )}
      {brand && <div className="brand">{brand}</div>}
    </div>
  );
};

export default ServiceItem;
