import React from "react";
import "./Services3.css"; // Import your CSS file

const services3 = () => {
  const impactData = [
    {
      value: "2.5x",
      description: "Growth in client base",
    },
    {
      value: "95%",
      description: "Customer satisfaction rate",
    },
    {
      value: "40+",
      description: "Projects successfully delivered",
    },
    {
      value: "100%",
      description: "Commitment to excellence",
    },
  ];

  return (
    <div className="impact-numbers-container">
      <p className="impact_num">Our Impact in Numbers</p>
      <div className="impact-numbers-grid">
        {impactData.map((item, index) => (
          <div key={index} className="impact-number">
            <p className="value">{item.value}</p>
            <p className="description">{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default services3;
