// src/components/Products.js
// import React from "react";
import ContactUs from "../ContactUs/ContactUs";
import React, { useState } from "react";
import Product from "./Product";
import archi from "../../Asset/Image/architect.jpg";
import virtual from "../../Asset/Image/Virtual tour.jpg";
import retail from "../../Asset/Image/retail.jpg";
import kids from "../../Asset/Image/kids.jpg";
import production from "../../Asset/Image/production-factory.jpg";
import medical from "../../Asset/Image/digital-medical.jpg";
const Products = () => {
  const [showContactForm, setShowContactForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const toggleContactForm = (product) => {
    setSelectedProduct(product);
    setShowContactForm(!showContactForm);
  };

  const products = [
    {
      title: "AR and VR Solutions in Architecture",
      subtitle: "AR VR in Architecture",
      description:
        "Elevate designs from static 2D to dynamic, realistic environments, transforming architectural visualization. Leverage virtual collaboration to craft prototypes, driving major cost and resource savings with AR and VR solutions for architecture. Revolutionize your design process today!",
      buttonText: "Call Us to Book Your Free Demo",
      imagePath: archi,
    },
    {
      title: "VR Solutions for Real Estate",
      subtitle: "Virtual Property Tours",
      description:
        "Transform real estate with immersive virtual property tours. Let buyers explore homes worldwide from anywhere, boosting engagement and simplifying purchases. Our VR solutions offer realistic, interactive experiences, revolutionizing how properties are viewed and sold in the digital age.",
      buttonText: "Schedule a Demo",
      imagePath: virtual,
    },
    {
      title: "AR in Retail",
      subtitle: "Enhanced Shopping Experience",
      description:
        "Transform the retail industry with augmented reality. Allow customers to see products in their own space before buying, minimizing returns and boosting satisfaction. This innovative approach enhances the shopping experience and drives higher customer engagement and sales.",
      buttonText: "Learn More",
      imagePath: retail,
    },
    {
      title: "VR Training Solutions",
      subtitle: "Immersive Learning Experiences",
      description:
        "Create safe and effective training environments with virtual reality. From industrial safety to soft skills development, VR training offers unparalleled engagement and retention.",
      buttonText: "Get Started",
      imagePath: kids,
    },
    {
      title: "AR in Manufacturing",
      subtitle: "Smart Factory Solutions",
      description:
        "Enhance manufacturing processes with augmented reality. Improve assembly line efficiency, reduce errors, and provide real-time guidance to workers with AR overlays.",
      buttonText: "Request Information",
      imagePath: production,
    },
    {
      title: "Mixed Reality for Healthcare",
      subtitle: "Advanced Medical Visualization",
      description:
        "Revolutionize healthcare with mixed reality solutions. From surgical planning to patient education, MR provides unprecedented ways to visualize and interact with medical data.",
      buttonText: "Book a Consultation",
      imagePath: medical,
    },
  ];

  return (
    <div className="products">
      {products.map((product, index) => (
        <Product
          key={index}
          index={index}
          title={product.title}
          subtitle={product.subtitle}
          description={product.description}
          buttonText={product.buttonText}
          imagePath={product.imagePath}
          onButtonClick={() => toggleContactForm(product)}
        />
      ))}
      {showContactForm && (
        <div className="contact-form-overlay">
          <div className="contact-form-container">
            <ContactUs
              product={selectedProduct}
              onClose={() => setShowContactForm(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Products;
