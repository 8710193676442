// src/components/VideoSection.js

import React, { useState, useRef, useEffect } from "react";
import "./VideoSection.css";
import arproduct from "../../Asset/video/3d Walkthrough web optimized.mp4";
import ariel from "../../Asset/video/Ariel Software optimized.mp4";

const VideoSection = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const videoRef = useRef(null);

  const videos = [
    {
      src: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/3d+Walkthrough+web+optimized.mp4",
      alt: "Video 1",
    },
    {
      src: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Ariel+Software+optimized.mp4",
      alt: "Video 2",
    },
    {
      src: "https://xeroverse-media.s3.ap-south-1.amazonaws.com/Ariel+Software+optimized.mp4",
      alt: "Video 3",
    },
  ];

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    }
  }, [currentVideo]);

  return (
    <div className="video_product">
      <section className="video-section">
        <h1 className="video-section-header">
          "Virtual Tours: Step into Your Dream Home, Anywhere, Anytime"
        </h1>
        <div className="video-container">
          <div className="video-buttons">
            {videos.map((video, index) => (
              <button
                key={index}
                className={`video-button ${
                  currentVideo === index ? "active" : ""
                }`}
                onClick={() => setCurrentVideo(index)}
              >
                AR {index + 1}
              </button>
            ))}
          </div>
          <div className="vid_wrap">
            <div className="video-wrapper">
              <video
                ref={videoRef}
                key={currentVideo}
                src={videos[currentVideo].src}
                controls
                autoPlay
                muted
                loop
                alt={videos[currentVideo].alt}
                className="video"
              />
            </div>

            <div className="text-container">
              <h2 className="video-title">Offers Immersive Virtual Tours</h2>
              <p className="video-description">
                Showcasing properties with stunning visuals and detailed views
                for informed decision making.
              </p>
              <ul className="video-features">
                <li>Visual Realism</li>
                <li>Customization and personalization</li>
                <li>Informed Decision-Making</li>
              </ul>
              <button className="info-button">Know More</button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default VideoSection;
