import React from "react";
import "./AboutUs.css";
import landing2 from "../../Asset/Image/Landing2.png";
import Prof from "../../Asset/Image/Intersect.png";
import Prof1 from "../../Asset/Image/prabhu.png";
import Ram from "../../Asset/Image/Ellipse 298.png";
import Shiva from "../../Asset/Image/Ellipse 299.png";
import Meva from "../../Asset/Image/Ellipse 305.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faGamepad,
  faCalendarAlt,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import visionIcon from "../../Asset/Image/vision.png";
import missionIcon from "../../Asset/Image/mission.png";

const About = () => {
  return (
    <>
      <div className="Abt-main">
        <div className="landing_page">
          <div className="parent">
            <div className="about">WHO</div>

            <div className="content1">
              <p className="long_st">
                Xeroverse is transforming Extended Reality (XR) and Digital Twin
                technologies with captivating VR, AR, and MR experiences. Our
                expert team excels in software development, 3D modeling, and
                data analytics, delivering innovative solutions that enhance
                business visualization and optimization. At Xeroverse, we’re not
                just creating technology; we’re redefining the future of digital
                interaction and industrial excellence.
              </p>
            </div>
          </div>
          <div>
            <img className="landing_image" src={landing2} alt="main"></img>
          </div>
        </div>

        <div className="about-us">
          <div className="stats-container">
            <div className="stat-item">
              <FontAwesomeIcon icon={faUsers} className="stat-icon" />
              <p className="Icon_text">15+</p>
              <p className="Icon_text1">Team</p>
            </div>
            <div className="stat-item">
              <FontAwesomeIcon icon={faGamepad} className="stat-icon" />
              <p className="Icon_text">50+</p>
              <p className="Icon_text1">Services</p>
            </div>
            <div className="stat-item">
              <FontAwesomeIcon icon={faCalendarAlt} className="stat-icon" />
              <p className="Icon_text">1+</p>
              <p className="Icon_text1">Years in Market</p>
            </div>
            <div className="stat-item">
              <FontAwesomeIcon icon={faUserTie} className="stat-icon" />
              <p className="Icon_text">20+</p>
              <p className="Icon_text1">Clients</p>
            </div>
          </div>
        </div>
        <div className="vision-mission-container">
          <div className="vision-mission-item">
            <img className="vis_img" src={visionIcon} alt="Vision Icon" />
            <div className="vis_cont">
              <div className="vision-mission-title">VISION</div>
              <div className="vision-mission-Subtitle">
                Reshaping Reality, Empowering Innovation{" "}
              </div>
              <div className="vision-mission-text">
                We envision a world where the boundaries between physical and
                digital realms blur, unleashing unprecedented creativity and
                efficiency. Xeroscape aims to be the catalyst that transforms
                how businesses visualize, interact, and innovate across all
                industries.
              </div>
            </div>
          </div>
          <div className="vision-mission-item">
            <img className="vis_img" src={missionIcon} alt="Mission Icon" />
            <div className="vis_cont">
              <div className="vision-mission-title">MISSION</div>
              <div className="vision-mission-Subtitle">
                Democratizing Digital Realities
              </div>
              <div className="vision-mission-text">
                We make XR and Digital Twin technologies accessible and
                transformative for all. Empowering businesses to innovate,
                visualize, and excel in the digital frontier. Bridging worlds,
                igniting imagination, and reshaping industries through
                affordable, cutting-edge solutions.
              </div>
            </div>
          </div>
        </div>
        <div className="team">
          <div className="dic">
            <div className="our">Founding Team</div>
            {/* <div className="people">Our Amazing People</div> */}
            <div className="comitted">
              We are committed to create a beautiful world of extended reality
            </div>
          </div>
          <div className="club">
            <div className="even">
              <div className="m">
                <figure>
                  <img src={Prof} alt="M"></img>
                  <figcaption>
                    <p className="Co_text">Prof. Krishnan Balasubramanian</p>
                    <p className="Co_tex">Mentor and Director</p>
                  </figcaption>
                </figure>
              </div>

              <div className="m">
                <figure>
                  <img src={Prof1} alt="M"></img>
                  <figcaption>
                    <p className="Co_text">Prof. Prabhu Rajagopal</p>
                    <p className="Co_tex">Mentor and Advisor</p>
                  </figcaption>
                </figure>
              </div>
              <div className="Artist1">
                <figure>
                  <img src={Ram} alt="Artist1"></img>
                  <figcaption>
                    <p className="Co_text">Ramashankar Yadav</p>
                    <p className="Co_tex">CEO and Co-founder </p>
                  </figcaption>
                </figure>
              </div>
            </div>
          </div>
          <div className="Co_Founder">
            <div className="xr">
              <figure>
                <img src={Shiva} alt="xr"></img>
                <figcaption>
                  <p className="Co_text">Sivaprasad KM</p>
                  <p className="Co_tex">Co-founder and XR Lead</p>
                </figcaption>
              </figure>
            </div>
            <div className="xr">
              <figure>
                <img src={Meva} alt="xr"></img>
                <figcaption>
                  <p className="Co_text">Mevalal Nishad</p>
                  <p className="Co_tex">Co-founder and DevOps Lead</p>
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default About;
