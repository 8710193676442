// import React, { useState } from "react";
// import emailjs from "emailjs-com";
// import {
//   FaFacebook,
//   FaLinkedin,
//   FaTwitter,
//   FaInstagram,
//   FaWhatsapp,
// } from "react-icons/fa";
// import "./ContactUs.css";

// const ContactUs = ({ closeModal }) => {
//   const [formData, setFormData] = useState({
//     fullName: "",
//     Number: "",
//     email: "",
//     message: "",
//   });
//   const [isLoading, setIsLoading] = useState(false);

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     emailjs
//       .send(
//         "service_qxcd66q",
//         "template_6hfuwnf",
//         {
//           to_email: "ramashankar8290@gmail.com",
//           from_name: formData.fullName,
//           from_email: formData.email,
//           message: formData.message,
//           contact_number: formData.Number,
//         },
//         "ugiBow7XqV4jFejlH"
//       )
//       .then(
//         (response) => {
//           console.log("SUCCESS!", response.status, response.text);
//           alert("Message sent successfully!");
//           setFormData({ fullName: "", Number: "", email: "", message: "" });
//           closeModal(); // Ensure this line is here
//         },
//         (error) => {
//           console.log("FAILED...", error);
//           alert("Failed to send message. Please try again.");
//         }
//       )
//       .finally(() => {
//         setIsLoading(false);
//       });
//   };

//   return (
//     <div className="modal_overlay" onClick={closeModal}>
//       <div className="contact_form" onClick={(e) => e.stopPropagation()}>
//         <h2 className="Cont_us">Contact Us</h2>
//         <form onSubmit={handleSubmit}>
//           <label>
//             Full Name:
//             <input
//               type="text"
//               name="fullName"
//               value={formData.fullName}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             Contact Number:
//             <input
//               type="tel"
//               name="Number"
//               value={formData.Number}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             Email ID:
//             <input
//               type="email"
//               name="email"
//               value={formData.email}
//               onChange={handleChange}
//               required
//             />
//           </label>
//           <label>
//             What can we help you with?
//             <textarea
//               name="message"
//               value={formData.message}
//               onChange={handleChange}
//               required
//             ></textarea>
//           </label>
//           <button type="submit" disabled={isLoading}>
//             {isLoading ? "Sending..." : "Submit"}
//           </button>
//         </form>
//         <div className="social-icons">
//           <a
//             href="[Your Facebook URL]"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaFacebook />
//           </a>
//           <a
//             href="[Your LinkedIn URL]"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaLinkedin />
//           </a>
//           <a
//             href="[Your Twitter URL]"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaTwitter />
//           </a>
//           <a
//             href="[Your Instagram URL]"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaInstagram />
//           </a>
//           <a
//             href="https://wa.me/[YourWhatsAppNumber]"
//             target="_blank"
//             rel="noopener noreferrer"
//           >
//             <FaWhatsapp />
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ContactUs;
import React, { useState } from "react";
import emailjs from "emailjs-com";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaWhatsapp,
} from "react-icons/fa";
import "./ContactUs.css";

const ContactUs = ({ product, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    Number: "",
    email: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    emailjs
      .send(
        "service_qxcd66q",
        "template_6hfuwnf",
        {
          to_email: "ramashankar8290@gmail.com",
          from_name: formData.fullName,
          from_email: formData.email,
          message: formData.message,
          contact_number: formData.Number,
          product_name: product ? product.title : "General Inquiry",
        },
        "ugiBow7XqV4jFejlH"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          alert("Message sent successfully!");
          setFormData({ fullName: "", Number: "", email: "", message: "" });
          onClose(); // Use onClose instead of closeModal
        },
        (error) => {
          console.log("FAILED...", error);
          alert("Failed to send message. Please try again.");
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="modal_overlay" onClick={onClose}>
      <div className="contact_form" onClick={(e) => e.stopPropagation()}>
        <h2 className="Cont_us">
          Contact Us {product ? `about ${product.title}` : ""}
        </h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Contact Number:
            <input
              type="tel"
              name="Number"
              value={formData.Number}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email ID:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            What can we help you with?
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
          </label>
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Sending..." : "Submit"}
          </button>
        </form>
        <div className="social-icons">
          <a
            href="[Your Facebook URL]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook />
          </a>
          <a
            href="[Your LinkedIn URL]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin />
          </a>
          <a
            href="[Your Twitter URL]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
          <a
            href="[Your Instagram URL]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://wa.me/[YourWhatsAppNumber]"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaWhatsapp />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
